import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LogoImage from "./logo-image"

const Header = ({ siteTitle }) => (
  <header style={{ marginBottom: "1em" }}>
    <nav className="navbar navbar-expand-lg navbar-dark"
      style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
        <span className="navbar-brand">
          <Link className="navbar-brand" to="/" style={{
            textDecoration: "none",
            color: "inherit",
            margin: "auto"
          }}>
            <LogoImage></LogoImage>
          </Link>
        </span>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
